@import '~antd/lib/style/themes/default.less';

.parents-meeting-event-card {
    margin: 16px 0;

    .content {    
        .live-badge {
            margin-left: 8px;
            .ant-badge-status-dot {
                width: 12px;
                height: 12px;
            }
        }
    
        .title {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            font-weight: 600;
            
            &-text {
                font-size: 18px;
                margin-bottom: 0.3rem;
                margin-right: 1rem;
            }

            .tags {
                margin: 4px 0px;
                margin-bottom: 0.3rem;
                @media screen and (max-width: @screen-xs-max) {
                    margin-bottom: 0.75rem;
                }
            }
        }

        .meeting-types {    
            svg {
                margin-top: 8px;
                margin-right: 20px;
                font-size: 22px;
            }
    
            display: flex;
            justify-content: flex-end;
        }  
    }

    .header {
        .extra {
            .date {
                .anticon {
                    margin-right: 6px;
                    font-size: 22px;
                }
                
                display: flex;
                align-items: center;
            }
        }

        @media screen and (max-width: 360px) {
            .anticon {
                display: none;
            }
        }
    }
}

@layout-body-background: #fafbfc;