@import '@less/variables.less';
@import '~antd/lib/style/themes/default.less';

.sb-mobile-event-menu {
    background-color: @bg-secondary;
    border-radius: 0.5rem;
    margin-top: 4px;
    padding: 1rem 0;

    .anticon {
        font-size: 20px;
        margin-right: 8px;
    }
    
    .ant-dropdown-menu-item-disabled {
        color: #516270;  
    }

    .ant-dropdown-menu-item-disabled:hover {
        color: #516270;  
    }

    li {
        align-items: center;
        color: white;
        display: flex;
        font-size: 16px;
        margin: 8px 0px;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    &__badge {
        margin-left: 0.5rem;
    }
}
@layout-body-background: #fafbfc;