.sb-no-events-placeholder {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    padding: 3rem!important;
    text-align: center;

    .ant-typography {
        color: white;
    }

    &__image {
        max-width: 350px
    }

    &__text {
        font-size: 1.1rem;
    }

    &__title {
        opacity: 0.75;
        margin-bottom: 0.5rem!important;
    }
}
@layout-body-background: #fafbfc;