@import '~antd/lib/style/themes/default.less';
@import '@less/variables.less';

.sb-booking-drawer {
    .ant-drawer-content {
        background-color: @bg-quaternary;

        .ant-typography {
            color:white!important;
        }
    }

    .ant-drawer-content-wrapper {
        max-width: 500px;
        width: 90%!important;

        @media screen and (max-width: @screen-xs-max) {
            width: 80%;
        }
    }

    .ant-drawer-header {
        background-color: @bg-quaternary-complimentary;
    }

    .anticon {
        color: white;
    }

    .ant-result-title {
        color: white;
    }

    .ant-result-subtitle {
        color:white
    }
    
    &__slot {
        display: flex;
        width: 100%;
    }

    &__slot-conflicted {
        overflow: hidden;
        text-align: center;
        text-overflow:ellipsis;
        white-space: nowrap;
        width: 300px;
    }

    &__slot-content {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-right: 1rem;
        white-space: nowrap;
        width: 100%;
    }
    
    &__slot-time {
        align-items: center;
        display: flex;
        width: 100%;

        .ant-switch {
            margin-right: 1rem;
        }
        
        .ant-tag:last-of-type {
            margin-right: 0;
        }
    }
}
@layout-body-background: #fafbfc;