@import '@less/variables.less';
@import '~antd/lib/style/themes/default.less';

.sb-event-details {
    &__header {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }
}
@layout-body-background: #fafbfc;