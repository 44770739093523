@import '~antd/lib/style/themes/default.less';
@import '@less/variables.less';

.sb-outstanding-invites {
    .sb-alert {
        margin-bottom: 1rem;
        row-gap: 1rem;
    }

    .sb-empty-message__description {
        color: white;
    }
    
    .sb-session-card {
        margin-bottom: 1rem;
    }

    &__ctas {
        display: flex;
        flex-wrap: wrap;

        button {
            margin-left: 0.3rem;
            margin-right: 0.3rem;
            margin-bottom: 0.5rem;
            
            @media screen and (max-width: @screen-xs) {
                width: 100%;
            }     
        }
    }

    &__sessions {
        margin-top: 0.5rem;
    }

    &__slots {       
        &--grid {
            column-gap: 0.6rem;
            display: flex;
            flex-wrap: wrap;
        }
    }
}
@layout-body-background: #fafbfc;