@import '~antd/lib/style/themes/default.less';

.sb-sidebar-menu {
    z-index: 100;
   
    .sb-simple-card {
        margin-bottom: 1rem;
    }
 
    @media screen and (min-width: @screen-lg) {
        display: block;
    }
}
@layout-body-background: #fafbfc;