.sb-event-list {
    .ant-list-empty-text {
        padding: 0.5rem 0rem;
        text-align: left!important;
    }

    &__header {
        h5 {
            margin-bottom: 0.25rem
        }
    }

    &__item {
        padding: 4px 0px!important;
        flex-wrap: nowrap!important;
        
        &--active {
            button {
                font-weight: 600;
                opacity: 1!important;
            }
        }
    }

    &__title {
        margin-bottom: 0.3rem;

        button {
            color:white;
            margin-right: 1rem;
            padding: 0;
            text-align: left;
            opacity: 0.8;
            white-space: break-spaces;
        }
    }

    &__badge {
        white-space: nowrap;
    }

    &__more {
        margin-top: 0.5rem;
        display: flex;
        justify-content: flex-end;
    }
}
@layout-body-background: #fafbfc;