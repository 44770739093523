@import '~antd/lib/style/themes/default.less';
@import '@less/variables.less';

.sb-loader {
  align-items: center;
  background: linear-gradient(90deg,#495972,#0e2352);
  display: flex;
  height:100vh;
  flex-direction: column;
  justify-content: center;
  padding: 0 2rem;
  width:100vw;

  &__animation {
    align-items: center;
    display: flex;
    height: 280px;
    justify-content: center;
    margin-left: -10px;
    position: relative;
    width: 280px;

    div {
      animation: sbloader 1.2s linear infinite;
      background: #fff;
      border-radius: 50%;
      height: 30%;
      margin: 10px;
      position: absolute;
      width: 30%;
    }
    
    div:nth-child(1) {
      animation-delay: 0s;
      background-color: @bg-logo-blue;
      left: 0;
      top: 0px;
    }
    
    div:nth-child(2) {
      animation-delay: -0.4s;
      background-color: @bg-logo-red;
      left: 33%;
      top: 0px;
    }
    
    div:nth-child(3) {
      animation-delay: -0.8s;
      background-color: @bg-logo-green;
      left: 66%;
      top: 0px;
    }
    
    div:nth-child(4) {
      animation-delay: -0.4s;
      background-color: @bg-logo-red;
      left: 0;
      top: 33%;
    }
    
    div:nth-child(5) {
      animation-delay: -0.8s;
      background-color: @bg-logo-green;
      left: 33%;
      top: 33%;
    }
    
    div:nth-child(6) {
      animation-delay: -1.2s;
      background-color: @bg-logo-blue;
      left: 66%;
      top: 33%;
    }
    
    div:nth-child(7) {
      animation-delay: -0.8s;
      background-color: @bg-logo-green;
      left: 0;
      top: 66%;
    }
    
    div:nth-child(8) {
      animation-delay: -1.2s;
      background-color: @bg-logo-blue;
      left: 33%;
      top: 66%;
    }
    
    div:nth-child(9) {
      animation-delay: -1.6s;
      background-color: @bg-logo-red;
      left: 66%;
      top: 66%;
    }
  
    @keyframes sbloader {
      0%, 100% {
        opacity: 1;
      }
  
      50% {
        opacity: 0.5;
      }
    }
  }

  &__title {
    color: white!important;
    font-size: 1.5rem!important;
    opacity: 0.8;
    text-align: center;
  }
}
@layout-body-background: #fafbfc;