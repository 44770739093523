@import '@less/variables.less';
@import '~antd/lib/style/themes/default.less';

.sb-container {
    margin: 0 auto;
    max-width: calc(@max-screen-width - 4rem);
    padding: 0 1rem;
    width: calc(100% - 2rem);
    
    @media screen and (max-width: @screen-md) {
        width: 100%;
    }   
    
    @media screen and (min-width: @screen-lg) {
        width: calc(100% - 4rem);
    }
}
@layout-body-background: #fafbfc;