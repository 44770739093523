@import '~antd/lib/style/themes/default.less';
@import '@less/variables.less';

.ant-menu-submenu .ant-menu {
    padding: 0.5rem
}

.sb-slot-card {
    align-items: stretch;
    background-color: @bg-secondary;
    border-radius: 10px;
    color: white;
    display: flex;
    flex-grow: 1;
    margin-bottom: 0.5rem;
    position: relative;
    
    &__content {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        width: 100%;
    }
    
    &__item {
        align-items: center;
        display: flex;
        margin: 0!important;
        
        .ant-menu-item-icon {
            font-size: 18px!important;
        }
    }
    
    &__controls {
        position: absolute;
        right: 0.25rem;
        top: 1rem;

        .ant-menu {
            background-color: transparent;
            border-bottom: none;
            line-height: 1.5rem;
        }

        .ant-menu-submenu {
            padding: 0!important;
        }

        .ant-menu-submenu-title {
            padding: 0;
        }

        svg {
            color: white;
            font-size: 1.5rem;
        }
    }

    &__cta {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-top: 1.5rem;
        width: 100%;
    }

    &__day {
        align-items: center;
        background-color: @bg-tertiary;
        border-radius: 10px 0px 0px 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 2rem;

        h1, h3 {
            margin: 0!important;
            color: white;
            word-break: normal;
        }

        @media screen and (max-width: @screen-xs) {
            display: none;
        }
    }

    &__group {
        color: white;

        h5 {
            color:white!important;
        }
    }

    &__heading {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    
    &__location {
        color: white;
    }

    &__notes {
        margin-top: 0.5rem;

        .ant-typography {
            color: white;
        }
    }

    &__student {
        color: white;
    }

    &__subject {
        color: white;
        
        h5 {
            color: white;
        }
    }

    &__time {
        margin-bottom: 0.5rem;
        opacity: 0.8;
        padding-right: 2rem;
 
        h5 {
            color: white;
            margin-bottom: 0.1;
        }
    }
}
@layout-body-background: #fafbfc;