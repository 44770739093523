@import '@less/variables.less';
@import '~antd/lib/style/themes/default.less';

.sb-badge {
    border-radius: 6px;
    color: white;
    font-weight: 500;
    white-space: nowrap;
    
    &--small {
        font-size: 0.8rem;
        padding: 2px 10px;
    }

    &--medium {
        padding: 4px 10px;
        font-size: 0.9rem;
    }

    &--large {
        padding: 6px 10px;
    }

    &--danger, &--error {
        background-color: @red-4;
    }

    &--info {
        background-color: @blue-4;
    }

    &--info-dark {
        background-color: @bg-info;
    }

    &--success {
        background-color: @green-4;
    }

    &--warning {
        background-color: @gold-4;
    }
}
@layout-body-background: #fafbfc;