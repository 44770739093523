.sb-empty-message {
    text-align: center;

    &__title {
        opacity: 0.75;
        margin-bottom: 0.2rem!important;
    }

    svg {
        max-width: 200px;
        width: 100%;
    }

    .ant-empty-image {
        height: 100%;
    }

    &__description {
        font-size: 1rem;
    }
}
@layout-body-background: #fafbfc;