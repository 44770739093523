.sb-session-countdown-timer {
  &__countdown, &__live {
    flex-wrap: wrap;
    width: 100%;
      
    .sb-alert__body {
      flex-grow: 10;
      margin-right: 2rem;
    }

    .sb-alert__right {
      display: flex!important;
      flex-grow: 1;
      margin-top: 0;
      padding-left: 0;
      
      button {
        flex-grow: 1;
      }
    }

    &-message {
      .ant-typography {
          margin-bottom: 0!important;
      }
    }

    &-time {
      font-weight: 700;
    }
    
    &--pulse {
      animation: pulse-blue 1s infinite;
    }
  }
}
@layout-body-background: #fafbfc;