@import '~antd/lib/style/themes/default.less';
@import '@less/variables.less';

.sb-alert {
    align-items: center;
    border-radius: 10px;
    display: flex;
    padding: 1rem 1.25rem;

    @media screen and (max-width: @screen-sm) {
        flex-wrap: wrap;
    }

    .ant-typography {
        color: white;
    }

    .ant-btn-background-ghost {
        color: white!important;
        border-color: white!important;
    }

    &__icon {        
        color:white;
        opacity: 0.8;
        margin-right: 1rem;

        .anticon {
            font-size: 3rem;
        }

        @media screen and (max-width: @screen-sm) {
            display: none;
        }     
    }
    
    &__message {
        font-size: 0.9rem!important;
    }

    &__right {
        @media screen and (max-width: @screen-sm) {
            margin-top: 1rem;
        }

        padding-left: 1rem;
        margin-left: auto;
    }

    &__title {
        font-size: 1rem!important;
        margin-bottom: 0.2rem!important;
        opacity: 0.8;
    }

    &--danger {
        background: linear-gradient(90deg, @bg-danger, @bg-danger-light)
    }

    &--info {
        background: linear-gradient(90deg, @bg-info, @bg-info-light)
    }
    
    &--success {
        background: linear-gradient(90deg, @bg-success, @bg-success-light)
    }

    &--warning {
        background: linear-gradient(90deg, @bg-warning, @bg-warning-light);
    }
}
@layout-body-background: #fafbfc;