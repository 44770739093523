@import '~antd/lib/style/themes/default.less';

.event-card {
    box-shadow: 0 0.5rem 0.5rem rgba(104, 101, 101, 0.15)!important;
    border-left: solid 5px #d8d4d4;
    border: none;
    
    &.gray {
        border-left-color: #d8d4d4;
    }

    &.red {
        border-left-color: @red-3;
    }

    &.blue {
        border-left-color: @blue-3;
    }

    &.green {
        border-left-color: @green-3;
    }

    .header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        
        .title {
            line-height: 30px;
            margin-right: 1rem;
        }

        .extra {
            font-weight: 400;
        }
    }
}

@layout-body-background: #fafbfc;