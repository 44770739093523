#activation-email {
  text-align: center;

  .intro {
    display: block;
    margin-bottom: 1.5em;
  }
}

#activation-email-sent {
  .resend-email {
    display: block;
    margin-top: 1.5em;
    text-align: center;
  }

  .success-message {
    .anticon {
      display: block;
      margin-bottom: 0.25em;
    }

    text-align: center;

    .email {
      margin-top: 0px;
    }
  }
}
@layout-body-background: #fafbfc;