@import '@less/variables.less';
@import '~antd/lib/style/themes/default.less';

.sb-event-picker-modal {
    .ant-modal-body {
        padding: 1.25rem;
    }
    
    .ant-modal-content {
        border-radius: 10px 10px 0 0;
    }

    .ant-modal-header {
        background-color: @bg-quaternary;
        border-radius: 10px 10px 0 0;

        .ant-typography {
            color:white
        }
    }

    .sb-empty-message__description {
        color: white;
    }
    
    &__header {
        h3 { 
            margin-bottom: 0.25rem;
        }
    
        font-weight: 300;
    }

    &__pagination {
        padding-top: 0.5rem;
        text-align: center;
    }

    &__search {
        margin-bottom: 1rem;
    }

    &__summary {
        display: flex;
        justify-content: space-between;

        .sb-badge {
            text-align: center;
            flex-grow: 1;
            margin: 0 0.2rem;
        }
    }
    
    @media screen and (max-width: @screen-sm) {
        margin: 1rem;   
        width: auto!important;
        
        .ant-modal-body {
            padding: 1rem 0.75rem;
        }
    }
}
@layout-body-background: #fafbfc;