@import '~antd/lib/style/themes/default.less';
@import '@less/variables.less';

.sb-notifications {
    width: 100%;
    
    &__date {
        align-items: center;
        display: flex;
        font-size: 1rem;
        justify-content: space-between;
        margin-bottom: 0.5rem;

        .ant-typography {
            color:white;
        }

        .sb-badge {
            margin-left: 1rem;
            white-space: nowrap;
        }
    }

    &__event-title {
        opacity: 0.8;
        margin-top: 0.5rem;
        margin-bottom: 0.2rem!important;
    }

    &__mark-read {
        margin-right: 0.5rem;
    }

    &__mark-read-label {
        white-space: nowrap;
    }

    &__message--read {
        opacity: 0.6;
    }

    &__search {
        display: flex;
        margin-bottom: 1rem;
        
        .ant-input-search {
            margin-right: 20px;
        }
    
        .ant-typography {
            margin-bottom: 0;
            white-space: nowrap;
        }
    
        @media screen and (max-width: @screen-xs-max) {
            display: block;
            
            &::after {
                clear: both;
                content: '';
                display: table;
                margin-bottom: 10px;
            }
        
            .ant-input-search {
                margin-bottom: 8px;
            }
        }
    }  

    &__search-label {
        margin-bottom: 0.2rem!important;
    }

    &__summary {
        margin-bottom: 0;
        margin-top: 1rem;
    
        @media screen and (max-width: @screen-xs-max) {
            .anticon-bell {
                display: none;
            }
        }

        svg {
            font-size: 24px;
        }
    } 

    &__title {
        font-size: 1.1rem;

        svg {
            margin-right: 0.5rem;
            @media screen and (max-width: @screen-xs-max) {
                display: none;
            }
        }
    }
        
    &__unread-switch {
        .ant-typography {
            font-size: 0.9rem;
            margin-bottom: 0;
        }
    
        @media screen and (max-width: @screen-xs-max) {
            float: right;
        }
    }

    .sb-card {
        margin-bottom: 1rem;
    }

    .sb-card__heading {
        flex-wrap: nowrap;
    }

    .sb-card__heading-actions {
        align-items: center;
        display: flex;
        font-size: 0.9rem;
        justify-content: flex-end;

        .ant-typography {
            margin-bottom: 0;
        }

        svg {
            margin-right: 0.3rem;
        }
    }

    .sb-notifications__search {
        .ant-input-affix-wrapper {
            border-radius: 1rem!important;
            border-width: 3px;
        }

        .ant-input-group-addon {
            display: none;
        }

        .ant-input-affix-wrapper, .ant-input {
            background-color: #365497;
        }

        .ant-input {
            color: white;
        }
    }
}
@layout-body-background: #fafbfc;