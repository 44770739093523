@import '@less/variables.less';
@import '~antd/lib/style/themes/default.less';

.sb-schedule {
    .ant-btn {
        color: white!important;
        
        :hover {
            color:white!important;
        }
    }

    .sb-alert {
        margin-bottom: 1rem;
    }

    .sb-empty-message__description {
        color:white;
    }

    &__action {

        &--small {
            @media screen and (min-width: @screen-sm) {
                display: none!important;
            }
        }

        &--large {
            @media screen and (max-width: @screen-sm) {
                display: none!important;
            }
        }
    }
    
    &__browser-support {
        margin-bottom: 1rem;
    }

    &__session {
        margin-top: 0.5rem;
    }

    &__slots {       
        &--grid {
            column-gap: 0.6rem;
            display: flex;
            flex-wrap: wrap;
        }
    }
}
@layout-body-background: #fafbfc;