@import '~antd/lib/style/themes/default.less';
@import '@less/variables.less';

.sb-card {
    border-radius: 10px;
    color: white;
    flex-grow: 1;
    margin-bottom: 0.5rem;
    overflow: auto;
    
    &--danger {
        background-color: @bg-danger;

        .sb-card__heading {
            background-color: @bg-danger-light;
        }
    }

    &--info {
        background-color: @bg-secondary;
        
        .sb-card__heading {
            background-color: @bg-tertiary;
        }
    }

    &--loading {
        .ant-skeleton-paragraph {
            margin-bottom: 0;

            li { 
                margin-left: 0;
            }
        }

        .sb-card__title {
            width: 100%;
            padding: 0.3rem 0;
        }
    }

    &--warning {
        background-color: @bg-warning;

        .sb-card__heading {
            background-color: @bg-warning-light;
        }
    }

    &__content {
        padding: 1rem 1.5rem;
    }
    
    &__heading {
        border-radius: 0.5rem 0.5rem 0 0;
        color:white!important;
        display: flex;
        flex-wrap: wrap;
        font-size: 1.15rem;
        gap: 0.75rem;
        justify-content: space-between;
        margin-bottom: 0;
        padding: 0.5rem 1.5rem;
        
        &-actions {
            flex-grow: 1;
        }
    }
    
    &__title {
        margin-right: 2rem;
        margin-bottom: 0!important;
        opacity: 0.7;
    }
}
@layout-body-background: #fafbfc;