.sidebar {
    height: 100%;
    min-height: 100vh;
    padding: 30px;
    max-width: 500px;
    min-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    justify-content: center;
    
    .ant-typography {
        color: white;
    }
    
    .ant-typography-danger {
        color: #ff4d4f;
        font-weight: 500;
    }

    label {
        color: white!important;
    }

    .error {
        text-align: center;
        margin-bottom: 20px;
    }

    .ant-form-item-tooltip {
        color: white!important;
    }

    .header {
        text-align: center;
        margin-bottom: 1em;

        .ant-typography {
            color: #ffffff94
        }
    }

    .subtitle {
        margin-top: 0px!important;
        font-weight: 300;
    }

    .title {
        margin-bottom: 0.10em;
    }

    .intro {
        font-size: 16px;
        margin-bottom: 1em;
    }

    .logo {
        margin-bottom: 1rem;
        width: 130px;
    }

    form {
        width: 100%;

        .ant-form-item {
            margin-top: 30px;
        }

        label {
            height: auto!important;
        }
    }
}

@media screen and (max-width: 1000px) {
    .sidebar {
        max-width: 400px;
        min-width: 400px;
    }
}

@media screen and (max-width: 750px) {
    .sidebar {
        max-width: 350px;
        min-width: 350px;
    }
}

@media screen and (max-width: 600px) {
    .blurb {
        display: none;
    }

    .react-card-flip {
        width: 100%;
    }

    
    .sidebar {
        max-width: 600px;
        min-width: 300px;
        width: 100%;    
    }
}
@layout-body-background: #fafbfc;