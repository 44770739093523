@import '@less/variables.less';
@import '~antd/lib/style/themes/default.less';

.sb-session-dropdown {
  align-items: center;
  display: flex;
  
  .ant-select-selector {
    color: black!important;
  }

  &__filter {
    width: 100%;
  }

  &__label {
    margin-right: 0.5rem;
  }

  &__select {
    width: 100%;
  }
}
@layout-body-background: #fafbfc;