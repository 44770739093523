.sb-user-menu {
    .avatar-item {
        position: relative;

        .sb-round-badge {
            position: absolute;
            right: -8px;
            top: -18px;
        }
    }
}
@layout-body-background: #fafbfc;