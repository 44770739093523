.sb-burger {
    
    /**
    * Burger container
    **/
    cursor: pointer;
    height: 14px;
    margin-top: 0;
    width: 16px;
    z-index: 100;
    
    /**
    * Burger bars
    **/
    em {
        background: white;
        display: block;
        height: 2px;
        margin-top: 4px;
        transition: transform .3s ease-in-out, opacity .3s ease-in-out;
        width: 100%;
    }
    
    /**
    * Remove top margin from the first Burger bar
    **/
    :first-child {
        margin-top: 0;
    }
 
    /**
    * Transform burger bars into a cross
    **/
    &.open {
        em {
            &:nth-child(1) {
                transform: translateY(6px) rotate(45deg);
            }
    
            &:nth-child(2) {
                opacity: 0;
            }
    
            &:nth-child(3) {
                transform: translateY(-6px) rotate(-45deg);
            }
        }
    }
}
@layout-body-background: #fafbfc;