@import '~antd/lib/style/themes/default.less';
@import '@less/variables.less';

.sb-simple-card {
    border-radius: 10px;
    box-shadow: 0 1rem 1rem -0.75rem rgb(42 42 43 / 18%);
    padding: 1.25rem;
    
    &__header {
        margin: -1.25rem -1.25rem 1rem -1.25rem;
        border-radius: 10px 10px 0 0;
        padding: 0.5rem 1rem;

        @media screen and (max-width: @screen-xs) {
            margin: -1rem -1rem 1rem -1rem;
        }
    }

    &__header--secondary {
        background-color: @bg-tertiary;
    }
    
    &__title {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.5rem!important;
        opacity: 0.75;
        h1, h2, h3, h4, h5, h6 {
            margin-bottom: 0;
        }
    }

    &__title-action {
        float: right;
        margin-left: 0.5rem;
    }

    &__title-badge {
        margin-left: 0.5rem;
    }

    &--primary, &--secondary, &--tertiary, &--quaternary, &--quinary, &--transparent, &--senary {
        h1, h2, h3, h4, h5, a, div {
            color: white!important;
        }
    }

    &--default {
        background-color: white;
    }

    &--primary {
        background-color: @bg-primary;
    }

    &--secondary {
        background-color: @bg-secondary;
    }

    &--tertiary {
        background-color: @bg-highlight;
    }

    &--transparent {
        background-color: transparent;
    }

    &--quaternary {
        background-color: @bg-quaternary;
        .cta {
            background-color: @bg-quaternary-complimentary;
            border-color: @bg-quaternary-complimentary;
        }   
    }

    &--quinary {
        background-color: @bg-quinary;
    }

    &--senary {
        background-color: @bg-senary;
    }

    @media screen and (max-width: @screen-xs) {
        padding: 1rem;
    }
}
@layout-body-background: #fafbfc;