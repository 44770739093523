@import '~antd/lib/style/themes/default.less';

.sb-error-page {
    align-items: center;
    background: linear-gradient(90deg,  rgba(73,89,114,1) 0%,  rgba(14,35,82,1) 100%);
    color: white;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    text-align: center;

    .ant-typography {
        color: white;
    }
    
    &__logo {
        max-height: 250px;
    }

    &__subtitle {
        margin-top: 0.5rem!important;
        font-size: 1.25rem!important;
        font-weight: normal!important;
    }

    &__title {
        font-size: 2rem!important;
        font-weight: 400!important;
        opacity: 0.85;
        margin-bottom: 0.5rem!important;
        margin-top: 0.5rem;
    }

    &__extra {
        margin-top: 1rem;
    }
}
@layout-body-background: #fafbfc;