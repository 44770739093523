.sb-system-message {
    &__old-version-button {
        display: block;
        margin: 0.75rem auto 0 auto!important;
    }

    &__old-version-content {
        text-align: center;
    }
}
@layout-body-background: #fafbfc;