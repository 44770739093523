@import '~antd/lib/style/themes/default.less';
@import '@less/variables.less';

.sb-hero {
    background: linear-gradient(90deg,  rgba(73,89,114,1) 0%,  rgba(14,35,82,1) 100%);
    background-color: @bg-header;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 0 0 100% 100%/0 0 3vw 3vw;
    color: white;
    margin-bottom: -90px;
    padding-bottom: 80px;
    padding-top: 1.5rem;
    width: 100%;
    
    &__actions {
        align-self: flex-start;
        display: flex;
        margin-left: auto;
        margin-bottom: 1.5rem;
    }
    
    &__blurb {
        align-items: center;
        display: flex;
        margin-right: 1rem;
    }
    
    &__image {
        margin-right: 1rem;
        
        svg {
            width: 130px;
        }
        
        @media screen and (max-width: @screen-lg) {
            display: none;
        }
    }
    
    &__logo {
        max-width: 260px;
        max-height: 200px;
    }

    &__sb-logo {
        max-height: 120px;
    }
    
    &__logo-container {
        display: flex;
        margin-right: 1.5rem;
        flex-grow: 0;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 16px;
        box-sizing: content-box;
        min-height: 100px;
        opacity: 0.95;
        overflow: hidden;
        padding: 1rem;
        max-width: 300px;

        @media screen and (max-width: @screen-lg) {
            margin-bottom: 1rem;
            margin-right: 0;
        } 
    }

    &__navigation {
        display: flex;
        justify-content: flex-end;
        margin-left: auto;
        margin-right: 1rem;
    }

    &__paragraph {
        color: white!important;
        font-size: 20px!important;
        margin-bottom: 0px!important;
    }

    &__subheader {
        color:rgb(255 255 255 / 85%)!important;
        margin-top: 0px!important;
        margin-bottom: 0.25rem!important;
    }

    &__title {
        font-size: 36px!important;
        color: white!important;
        margin-bottom: 0.25rem!important;
    }

    &__top-bar {
        align-items: center;
        display: flex;
        width: 100%;
        margin-bottom: 3rem;

        @media screen and (max-width: @screen-lg) {
            flex-direction: column;
            justify-content: center;
            text-align: center;
        } 
    }

    &__wrapper {
        margin-bottom: 1.5rem;
        position: relative;
    }
}

@layout-body-background: #fafbfc;