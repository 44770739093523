@import '@less/variables.less';
@import '~antd/lib/style/themes/default.less';

.sb-custom-modal {
    max-width: 750px;

    .ant-modal-body {
        padding: 1rem;
    }

    .ant-modal-close-x {
        color: white;
    }

    .ant-modal-content {
        @media screen and (max-width: @screen-xs) {
            margin: 0.5rem;
        }            

        background-color: '#d9dbdd';
        border-radius: 10px 10px 0 0;
        margin: 2rem;
    }
        
    .ant-modal-footer {
        background-color: @bg-modal-footer;
    }

    .ant-modal-header {
        background-color: @bg-modal-header;
        border-radius: 10px 10px 0 0;

        .ant-typography {
            color: white;
        }
    }

    .ant-modal-title {
        h3.ant-typography { 
            margin-bottom: 0.25rem;
        }
        
        div.ant-typography {
            font-weight: 300;
            margin-bottom: 0;
        }
    }

    @media screen and (max-width: @screen-sm) {
        margin: 1rem;   
        width: auto!important;
    }       
}
@layout-body-background: #fafbfc;