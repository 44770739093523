@import '~antd/lib/style/themes/default.less';
@import '@less/variables.less';

.sb-footer {
    align-self: flex-end;
    background: linear-gradient(90deg,  rgba(73,89,114,1) 0%,  rgba(14,35,82,1) 100%);
    background-color: @bg-header;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    color: white;
    margin-top: auto;
    padding-bottom: 2rem;
    width: 100%;

    .ant-typography {
        color: white;
    }

    h5 {
        margin-bottom: 0.2rem;
        opacity: 0.75;
    }

    
    &__build {
        div.ant-typography {
            font-size: 0.85rem;
        }
    }
    
    &__container {
        display: flex;
        justify-content: space-between;
    }
    
    &__copyright {
        font-size: 0.85rem;
        
        .ant-typography {
            margin-bottom: 0rem;
        }
    }
    
    &__explore {
        a {
            color: white;
            display: block;
            font-size: 0.85rem;
        }
    }
    
    &__guest {
        font-size: 0.85rem;
        max-width: 280px;

        @media screen and (max-width: @screen-sm) {
            display: none;
        }     
    }
    
    &__guest-blurb {
        margin-bottom: 0.25rem!important;
    }

    &__guest-links {
        color: @link-light;
        margin-top: 0.25rem;

        a {
            color: @link-light;
        }

        a:not(:last-child):after {
            content: '|';
            margin-left: 0.25rem;
            margin-right: 0.25rem;
        }
    }

    &__powered-by {
        display: flex;
        flex-direction: column;
        text-align: center;
        
        img {
            height: 54px;
            padding-right: 8px;
            margin-bottom: 4px;
        }
    }

    &__wave {
        margin-bottom: 1.5rem;
    }
}
@layout-body-background: #fafbfc;