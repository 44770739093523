@import '@less/variables.less';
@import '~antd/lib/style/themes/default.less';

.sb-event-summary {
    &__status {
        margin-left: 0.5rem;
    }

    &__sub-title {
        margin-top: 0px!important;
    }
}
@layout-body-background: #fafbfc;