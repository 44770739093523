@import '@less/variables.less';
@import '~antd/lib/style/themes/default.less';

.sb-event-picker-card {
    align-items: center;
    border-radius: 0.5rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    margin-bottom: 0.5rem;
    overflow-x: hidden;

    h5 {
        margin-bottom: 0.5rem;
        margin-right: 0.5rem;
        opacity: 0.9;
    }
    
    &__action {
        @media screen and (max-width: @screen-xs) {
            display: none;
        }            
    }

    &__body {
        @media screen and (min-width: @screen-xs) {
            margin-right: 2rem;
        }      
    }

    &--current {
        background-color: @current-dark;
        color: white;        
        padding: 2rem;

        @media screen and (max-width: @screen-xs) {
            padding: 1rem;
        }
              
        h5 {
            font-size: 1.5rem;
            color: white;
        }

        .sb-event-picker-card__date {
            font-size: 1rem;
            font-weight: 500;
        }

        .sb-event-picker-card__action {
            font-size: 2rem;
        }
    }
    
    &__date { 
        @media screen and (max-width: @screen-xs) {
            .icon {
                display: none;
            }
        }
    }

    &__description {
        color:white;
        font-weight: 300;
        margin-top: 1rem;
    }

    &--expired {
        background-color: @expired-light;
    }

    &--expired, &--upcoming {
        .sb-event-picker-card__action {
            font-size: 1.25rem;
        }
    }

    .sb-badge {
        margin-bottom: 0.5rem;
        white-space: nowrap;
    }

    &__title {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    &--upcoming {
        color: white;
        background-color: @upcoming-dark;

        h5 {
            color: white;
        }
    }

    @media screen and (max-width: @screen-sm-max) {
        .ant-modal-body {
            padding: 1rem;
        }
    }
}
@layout-body-background: #fafbfc;