@import '~antd/lib/style/themes/default.less';
@import '@less/variables.less';

.sb-ticker-tape {
    background: linear-gradient(90deg, #1b79c4 0%, #303f9f 100%);
    border-bottom: solid 1px #ffff;
    bottom:0;
    box-shadow: 0px 0px 6px rgb(44 73 165);
    padding: 1.5rem;
    position: sticky;
    text-align: center;
    width: 100%;

    h4 {
        color: white!important;
    }
    
    &__body {
        color: white;
        font-size: 14px;
    }

    &__title {
        margin-bottom: 0!important;
        opacity: 0.75;
    }
}

@layout-body-background: #fafbfc;