.settings-menu-button {
    .anticon-setting {
        font-size: 22px;
        position:relative;
        top: 1.5px;
    }
}

.settings-menu {
    .anticon {
        font-size: 20px;
    }
}
@layout-body-background: #fafbfc;