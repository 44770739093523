#school-contact-details {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 8px 20px;
  justify-content: center;
  margin-bottom: 1rem;

  .contact-detail {
    align-items: center;
    color: #ffffff94;
    display: flex;
    font-size: 1rem;
    font-weight: 400;

    svg {
      fill: #ffffff94;
      height: 24px;
      margin-right: 6px;
    }

    text-align: center;
  }
}
@layout-body-background: #fafbfc;