@import '~antd/lib/style/themes/default.less';
@import '@less/variables.less';

body {
    background-color: @bg-body;
}

.sb-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    &__content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-bottom: 1.5rem;
    }

    &__impersonate {
        background-color: @bg-secondary;

        .ant-typography {
            color: white;
            font-size: 0.85rem;
            margin-bottom: 0;
        }

        padding: 0.5rem 0rem;
    }

    &__impersonate-container {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    &__impersonate-user {
        font-weight: 600;
    }

    &__logout {
        background-color: @bg-quaternary;
        border-color: @bg-quaternary;
        margin-left: 1rem;
    }
}
@layout-body-background: #fafbfc;