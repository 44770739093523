@import '@less/variables.less';
@import '~antd/lib/style/themes/default.less';

.ant-empty {
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .ant-empty-image {
        height: auto!important;
        width: 100%;

        img {
            max-width: 500px;
            width: 100%;
        }
    }
}

.ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.8)!important;
}

.ant-popover {
    max-width: 200px;
}

.ant-page-header-content {
    padding-top: 2px;
    .ant-typography { 
        font-size: 16px;
    }
}

.tag {
    padding: 2px 20px;
}

.shadow {
    box-shadow: 0 0.5rem 0.5rem rgba(104, 101, 101, 0.15)!important;
}

.tag-large {
    display: inline-flex;
    align-items: center;
    padding: 4px 20px;
    font-weight: 600;
    .anticon {
        font-size: 20px;
    }
}

.warning {
    color: @gold-6;   
}

.info {
    color: @blue-6;
}

.event-notification {
    h4 {
        margin-top: 0px!important;
        margin-bottom: 0px!important;
    }

    h5 {
        margin-bottom: 6px;
    }
}

.event-notification-warning {
    h4 {
        color: @gold-6;
    }
}

.event-notification-info {
    h4 {
        color: @blue-6;
    }
}

.sb-custom-menu {
    padding-top: 10px;

    .anticon {
        font-size: 20px;
        margin-right: 8px;
    }

    li {
        display: flex;
        align-items: center;
        padding-left: 30px;
        padding-right: 30px;
        margin: 8px 0px;
        font-size: 16px;
    }
}


.hidden-xxl {
    @media screen and (max-width: @screen-xxl) {
        display: none!important;
    }
}

.hidden-xl {
    @media screen and (max-width: @screen-xl) {
        display: none!important;
    }
}

.hidden-lg {
    @media screen and (max-width: @screen-sm) {
        display: none!important;
    }
}

.hidden-sm {
    @media screen and (max-width: @screen-sm) {
        display: none!important;
    }
}

.hidden-xs {
    @media screen and (max-width: @screen-xs) {
        display: none!important;
    }
}

.fade-in {
    height: 100%;
    opacity: 1;
    transition: opacity 1s;
}

.fade-out {
    height: 0;
    opacity: 0;
    transition: opacity 0s;
}

@keyframes pulse-blue {
    0% {
        box-shadow: 0 0 0 0 rgba(24, 144, 255, 1);
    }

    70% {
        box-shadow: 0 0 0 10px rgba(24, 144, 255, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(24, 144, 255, 0);
    }    
}

@keyframes pulse-orange {
    0% {
        box-shadow: 0 0 0 0 rgba(250, 173, 20, 1);
    }

    70% {
        box-shadow: 0 0 0 10px rgba(250, 173, 20, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(250, 173, 20, 0);
    }
}