.sb-icon-item {
    display: flex;
    align-items: center;
    
    .icon {
        margin-right: 0.7rem;
        font-size: 20px;
        
        &.medium {
            font-size: 20px;
        }

        &.large {
            font-size: 24px;
        }

        &.xxl {
            font-size: 60px;
        }
    }

    .text {
        .ant-typography {
            margin-bottom: 0;
        }
    }
}
@layout-body-background: #fafbfc;