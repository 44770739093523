@import '~antd/lib/style/themes/default.less';
@import '@less/variables.less';

.sb-comments-modal {
    color: white!important;
    max-width: 650px;

    .ant-input-textarea-show-count::after {
        color: white!important;
    }

    .ant-modal-content {
        background-color: @bg-quaternary-complimentary;
    }
    
    .ant-modal-footer {
        background-color: @bg-quaternary!important;

        .ant-btn-primary {
            background-color: @bg-quaternary-complimentary!important
        }
    }

    .ant-typography {
        color: white;
    }

    .sb-badge {
        margin-bottom: 0.25rem;
        max-width: 230px;
        flex-grow: 1;
        text-align: center;
    }

    .sb-empty-message__description {
        color: white;
    }

    &__form {
        color: white!important;
    }

    &__form-intro {
        margin-bottom: 0.4rem!important;
    }

    &__summary-actions {
        display: flex;
        justify-content: flex-end;
    }

    &__summary-note {
        color: white!important;
    }

    &__summary-title {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 0.75rem;
        gap: 0 1rem;


        .ant-typography {
            margin-bottom: 0;
        }
    }    
}
@layout-body-background: #fafbfc;