@import '~antd/lib/style/themes/default.less';
@import '@less/variables.less';

.sb-video-conference-modal {   
    height: 95vh;
    max-height: 1000px;
    max-width: 736px!important;

    .ant-modal {
        height: 100%;
        max-width: 784px;
    }

    .ant-modal-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin: 0.5rem;
    }
 
    .ant-modal-body {
        background-color: #292929;
        height: 100%;
        overflow: hidden!important;
        padding: 0;
    }
    
    .ant-modal-header {
        padding: 1rem;
    }

    .prejoin-input-area {
        margin-top: -50px;
    }
    
    .sb-loader {
        // height: 70vh;
        height:100%;
        width: 100%!important;
    }

    .sb-simple-card {
        margin: 1rem;
    }

    #jitsiMeeting-1 {
        height: 100%
    }

    // .ant-empty-footer {
    //     margin-top: 0.5rem;
    // }

    &__date {
        display: flex;
        justify-content: space-between;
        margin-top: 6px;
    }

    &__expired-text {
        font-size: 1rem;

        .ant-typography {
            margin-bottom: 0.3rem;
        }

        margin-bottom: 1.5rem;
    }
    
    
    &__time {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        font-size: 1rem;
        justify-content: space-around;
        line-height: 1.75rem;
        margin-top: 0.5rem;
        padding: 0.4rem 0.75rem;

        &--countdown {
            animation: pulse-orange 1s infinite;
        }
    }

        
    @media screen and (max-width: 576px) {
        margin: 0rem;
        width: 98%!important;
    }
}
@layout-body-background: #fafbfc;