.sb-image-radio-group {
    display: flex;
    flex-wrap: wrap;
}

.sb-image-radio {
    cursor: pointer;
    flex-grow: 1;
    flex-shrink: 1;
    box-shadow: 0 1rem 1rem -0.75rem rgb(42 42 43 / 18%);
    margin: 0.5rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 1rem;
    border-radius: 1rem;
    
    &--default {
        background-color: #dee0e2;
        .sb-image-radio__description, .sb-image-radio__title {
            color: gray!important;
        }

    }

    &--selected {
        background-color: #52c41a;
        border: solid 6px #d1f3c0;

        .sb-image-radio__description, .sb-image-radio__title {
            color: white!important;
        }
    }

    &__description {
        text-align: center;
    }

    &__title {
        margin-top: 0.5rem;
        margin-bottom: 0.25rem!important;
    }

    &__image {
        svg {
            height: 100px;
        }
    }

    &__selected {
        svg {
            color: #d1f3c0;
        }

        font-size: 50px;
        position: absolute;
        top: -10px;
        right: -10px;
    }
}
@layout-body-background: #fafbfc;