@import '~antd/lib/style/themes/default.less';
@import '@less/variables.less';

.sb-invitation-card {
    background-color: @bg-secondary;
    border-radius: 10px;
    color: white;
    flex-grow: 1;
    margin-bottom: 0.5rem;

    &__controls {
        align-items: center;
        display: flex;
        margin-left: 1rem;
        
        @media screen and (max-width: @screen-sm) {
            display: none;    
        }
    }

    &__content {
        padding: 1rem 1.5rem;
    }
    
    &__heading {
        align-items: center;
        background-color: @bg-tertiary;
        border-radius: 0.5rem 0.5rem 0 0;
        display: flex;
        justify-content: space-between;
        padding: 0.5rem 1.5rem;
        
        h4 {
            color:white!important;
            font-size: 1.15rem;
            margin-bottom: 0;
            opacity: 0.7;
        }
    }

    &__status--mobile {
        margin-bottom: 1rem;

        @media screen and (min-width: calc(@screen-sm ~ "+" 1px)) {
            display: none;    
        }

        .sb-badge {
            display: block;
            text-align: center;
            width: 100%;
        }
    }

    &__subject, &__student, &__location {
        color: white;
        margin-right: 2rem;
    }

    &__notes {
        margin-top: 0.5rem;
        width: 100%;

        .ant-typography {
            color: white;
            margin-bottom: 0;
        }
    }

    &__book-button {
        margin-left: 1rem;
    }
    
    &__book-button--mobile {
        margin-top: 1.5rem;

        @media screen and (min-width: calc(@screen-sm ~ "+" 1px)) {
            display: none;    
        }
    }
}
@layout-body-background: #fafbfc;