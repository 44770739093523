.sb-terminal-error {
    padding: 3rem!important;
    text-align: center;

    .ant-typography {
        color: white;
    }

    &__image {
        max-width: 100%;
        max-height: 450px;
    }

    &__text {
        font-size: 1.1rem;
    }

    &__title {
        opacity: 0.75;
        margin-bottom: 0.5rem!important;
    }
}
@layout-body-background: #fafbfc;