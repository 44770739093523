@import '@less/variables.less';
@import '~antd/lib/style/themes/default.less';

.sb-session-card {
    &__content {
        background-color: @bg-session;
        border-radius: 0 0 0.5rem 0.5rem;
        padding: 0.75rem;
    }

    &__no-appointments {
        margin-left: 0.5rem;
        white-space: nowrap;

        @media screen and (max-width: @screen-sm) {
            display: none;    
        }
    }

    &__title {
        align-items: center;
        background-color: @bg-highlight;
        border-radius: 0.5rem 0.5rem 0 0;
        display: flex;
        padding: 0.5rem 1rem;
        justify-content: space-between;

        @media screen and (max-width: @screen-sm-max) {
            .anticon {
                display: none;
            }
        }

        h5 { 
            color: white;
            margin-bottom: 0;
        }
    }
}
@layout-body-background: #fafbfc;