@import '@less/variables.less';
@import '~antd/lib/style/themes/default.less';

.sb-session-controls {
  align-items: center;
  background-color: @bg-session;
  border-radius: 0.5rem;
  color: white;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0.5rem 1rem;

  .sb-session-dropdown {
    width: auto;

    .ant-select-selector {
      border-radius: 10px;
    }
    
    &__label {
      @media screen and (max-width: @screen-sm) {       
        display: none;
      }
    }

    &__option-date-range {
      @media screen and (max-width: @screen-sm) {       
        display: none;
      }
    }
  }
  
  
  &__display {
    margin-left: auto;

    .ant-radio-group {
      display: flex;
      
      :first-of-type {
        border-radius: 6px 0px 0px 6px;
      }

      :last-of-type {
        border-radius: 0px 6px 6px 0px;
      }
    }



    @media screen and (max-width: @screen-sm) {       
      display: none;
    }
  }

  &__filter {
    flex-grow: 1;
    min-width: 0;
  }
}
@layout-body-background: #fafbfc;