@import '~antd/lib/style/themes/default.less';

.sb-round-badge {
    align-items: center;
    background-color: #ff9800;
    border-radius: 50%;
    display: flex;
    font-size: 14px;
    font-weight: 700;
    height: 26px;
    justify-content: center;
    padding: 4px;
    width: 26px;

    &--danger, &--error {
        background-color: @red-6;
    }

    &--info {
        background-color: @blue-6;
    }

    &--success {
        background-color: @green-6;
    }

    &--warning {
        background-color: @gold-6;
    }
}
@layout-body-background: #fafbfc;